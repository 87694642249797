import ProfileView from "../../components/Employee/profile-view";
import Layout from "../../components/Layout";

function ProfileViewPage() {
  return (
    <>
      <div>
        <Layout>
          <ProfileView />
        </Layout>
      </div>
    </>
  );
}export default ProfileViewPage;
