
import RejectedLeave from "../../components/Employee/rejected-leave";
import Layout from "../../components/Layout";

function RejectedLeavePage(){
    return(
        <>
        <div>
            <Layout>
               <RejectedLeave/>
            </Layout>
        </div>
        </>
    );
}
export default RejectedLeavePage;