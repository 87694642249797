import HodDashboard from "../../components/hod/hod-dashboard";
import Layout from "../../components/Layout";

function HodDashboardPage() {
  return (
    <>
      <div>
        <Layout>
          <HodDashboard />
        </Layout>
      </div>
    </>
  );
}
export default HodDashboardPage;
