import AccountantDashboard from "../../components/Accoutant/accountant-dashboard";
import Layout from "../../components/Layout";

function AccountantDashboardPage() {
  return (
    <>
      <div>
        <Layout>
          <AccountantDashboard />
        </Layout>
      </div>
    </>
  );
}
export default AccountantDashboardPage;