import Layout from "../components/Layout";
import VehicleDetail from "../components/vehicle-detail";

function VehicleDetailPage() {
  return (
    <>
      <div>
        <Layout>
          <VehicleDetail />
        </Layout>
      </div>
    </>
  );
}
export default VehicleDetailPage;