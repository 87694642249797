import AllAcceptedLeaveRequest from "../../components/Admin/all-accepted-leave-request";
import Layout from "../../components/Layout";

function AllAcceptedLeaveRequestPage() {
  return (
    <>
      <div>
        <Layout>
          <AllAcceptedLeaveRequest />
        </Layout>
      </div>
    </>
  );
}
export default AllAcceptedLeaveRequestPage;
