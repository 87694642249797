import PendingApplication from "../../components/Employee/pending-application";
import Layout from "../../components/Layout";

function PendingApplicationPage() {
    return(
        <>
        <div>
            <Layout>
               <PendingApplication/>
            </Layout>
        </div>
        </>
    );
}
export default PendingApplicationPage;