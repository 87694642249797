import Layout from "../components/Layout";
import AddVehicle from "../components/add-vehicle";

function AddVehiclePage() {
  return (
    <>
      <div>
        <Layout>
          <AddVehicle />
        </Layout>
      </div>
    </>
  );
}
export default AddVehiclePage;