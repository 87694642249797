import Layout from "../components/Layout";
import ListEmployee from "../components/ListEmployee";


function ListEmployeePage(){
    return(
        <>
        <div>
            <Layout>
                <ListEmployee/>
            </Layout>
        </div>
        </>
    );
}
export default ListEmployeePage