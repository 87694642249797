
import ApprovedLeave from "../../components/Employee/approved-leave";
import Layout from "../../components/Layout";

function ApprovedLeavePage(){
    return(
        <>
        <div>
            <Layout>
               <ApprovedLeave/>
            </Layout>
        </div>
        </>
    );
}
export default ApprovedLeavePage;