import Changepassword from "../../components/Employee/change-password";
import Layout from "../../components/Layout";

function ChangePasswordPage() {
  return (
    <>
      <div>
        <Layout>
          <Changepassword />
        </Layout>
      </div>
    </>
  );
}
export default ChangePasswordPage;
