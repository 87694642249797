import EmployeeDashboard from "../../components/Employee/employee-dashboard";
import Layout from "../../components/Layout";


function EmployeeDashboardPage(){
    return(
        <>
        <div>
            <Layout>
                <EmployeeDashboard/>
            </Layout>
        </div>
        </>
    );
}
export default EmployeeDashboardPage