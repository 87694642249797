import Layout from "../components/Layout";
import VehicleList from "../components/vehicle-list";

function VehicleListPage() {
  return (
    <>
      <div>
        <Layout>
          <VehicleList />
        </Layout>
      </div>
    </>
  );
}
export default VehicleListPage;