import AllPendingLeaveRequest from "../../components/Admin/all-pending-leave-request";
import Layout from "../../components/Layout";

function AllPendingLeaveRequestPage() {
  return (
    <>
      <div>
        <Layout>
          <AllPendingLeaveRequest />
        </Layout>
      </div>
    </>
  );
}
export default AllPendingLeaveRequestPage;
