import NotFound from "../components/NotFound";


function NotFoundPage(){
    return(
        <>
        <div> 
            <NotFound/>
        </div>
        </>
    )
}
export default NotFoundPage