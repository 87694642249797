import Layout from "../components/Layout";
import EmployeeLeaveEligibility from "../components/employee-leave-eligibility";

function EmplpoyeeLeaveEligibilityPage() {
  return (
    <>
      <div>
        <Layout>
          <EmployeeLeaveEligibility />
        </Layout>
      </div>
    </>
  );
}
export default EmplpoyeeLeaveEligibilityPage;
