import Layout from "../components/Layout";
import PendingAllEmployeeLeave from "../components/pending-all-employee-leave";

function PendingAllEmployeeLeavePage() {
  return (
    <>
      <div>
        <Layout>
          <PendingAllEmployeeLeave />
        </Layout>
      </div>
    </>
  );
}
export default PendingAllEmployeeLeavePage;
