
import PendingLeave from "../../components/Employee/pending-leave";
import Layout from "../../components/Layout";

function PendingLeavePage(){
    return(
        <>
        <div>
            <Layout>
               <PendingLeave/> 
            </Layout>
        </div>
        </>
    );
}
export default PendingLeavePage;